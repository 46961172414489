.password-generator .header {
  color: #282c34;
  position: relative;
  padding: 55px 0;
  text-align: center;
}

.password-generator .h1 {
  color: #ff554f;
  font-size: 2.5rem !important;
  text-align: center;
  padding: 0 10px;
}

.password-generator h4 {
  color: #506175;
  font-size: 1.5rem;
}
