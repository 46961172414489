.loading {
  text-align: center;
  width: 100%;
}

#create-operation .create-button {
  margin-bottom: 10px;
  float: right;
}

h1 {
  font-size: 24px;
}

#button-action {
  margin-top: 20px;
}

.codeCount {
  font-size: 10px;
  font-weight: bold;
  text-transform: lowercase;
  position: absolute;
  top: 10px;
  right: 15px;
}

.payment-page tfoot td {
  font-weight: bold;
  text-transform: uppercase;
  text-align: right;
  border: 0 !important;
  padding: 0 !important;
}

.payment-page tbody td,
.payment-page thead th {
  text-align: right;
  padding: 10px 0 10px 10px !important;
}

img.full {
  width: 100%;
}

.block-payment {
  margin-top: 20px;
}

@media (max-width: 576px) {
  .codeCount {
    display: none;
  }
}

@media (max-width: 767px) {
  .navbar-nav {
    margin: 0;
  }
}

.code-example-container p {
  font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter,
    monospace;
}
