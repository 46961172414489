body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.page-content {
  margin-bottom: 10px;
  min-height: 600px;
}

.btn-xs {
  height: 20px;
  line-height: 15px;
  margin: 0;
  padding: 2px 5px;
}

#button-action {
  border: 0;
}

.navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-toggler-icon {
  font-family: "FontAwesome";
  color: #fff;
  padding-top: 4px;
}

.collapse.show,
.collapsing {
  display: block;
  padding-left: 10px;
}

.collapse.show li a,
.collapsing li a {
  padding: 10px 0 0 15px !important;
  margin: 0 !important;
}

tfoot td {
  font-weight: bold;
  text-transform: uppercase;
}

footer ul {
  list-style-type: none;
}

.operationForm .errors {
  margin-bottom: 0;
}
